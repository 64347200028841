@font-face {
  font-family: 'Myriad';
  src: url('../assets/fonts/MyriadPro-Regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'League Spartan';
  src: url('../assets/fonts/LeagueSpartan-Bold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Josefin Sans';
  src: url('../assets/fonts/JosefinSans-Regular.woff2') format('woff2');
  font-display: swap;
}
